<template>
  <f7-page class="settings-page">
    <template #fixed>
      <ProfileNavigationComponent :title="$t.getTranslation('LBL_ELIGIBLE_ORDER')" type="back" :settings="false" />
    </template>

    <section class="referral-section">
      <div class="container">
        <div v-if="orderList.length > 0" class="referral-list">
          <div v-for="order in orderList" :key="'rf_' + order.UserId" class="referral">
            <div class="info">
              <div>
                <h1>{{ $h.getFullName(order) || order.UserName || order.Email }}</h1>
                <p class="contact">{{ $h.formatMobile(order) }}</p>
              </div>
              <div class="order">
                <span>{{ $t.getTranslation("LBL_ORDER_NO") }}</span>
                <p>{{ order.OrderNumber || "--" }}</p>
              </div>
            </div>
            <div class="commission-info">
              <div>
                <p>{{ $t.getTranslation("LBL_ELIGIBLE_COMMISSION_AMOUNT") }}</p>
                <p>{{ $h.formatNumber(order.GroupCommission) || 0 }}</p>
              </div>
              <div>
                <p>{{ $t.getTranslation("LBL_REDEEMABLE_COMMISSION_AMOUNT") }}</p>
                <p>{{ $h.formatNumber(order.RedeemableCommissionAmount) || 0 }}</p>
              </div>
              <div>
                <p>{{ $t.getTranslation("LBL_REDEEMED_COMMISSION_AMOUNT") }}</p>
                <p>{{ $h.formatNumber(order.RedeemedCommissionAmount) || 0 }}</p>
              </div>
              <div>
                <p>{{ $t.getTranslation("LBL_COMMISSION_STATUS") }}</p>
                <p :class="{ pending: order.IsGroupCommissionClaimed === 0 }">{{ order.IsGroupCommissionClaimed === 1 ? $t.getTranslation("LBL_REDEEMED") : $t.getTranslation("LBL_PENDING") }}</p>
              </div>
            </div>
          </div>
        </div>

        <NoDataFoundComponent v-else :size="'sm'" :title="$t.getTranslation('LBL_ORDER_EMPTY')" />
      </div>
    </section>
  </f7-page>
</template>

<script>
import { defineComponent, onMounted, ref, defineAsyncComponent } from "vue";
import { useStore } from "@/store";

import { get } from "@/utils/axios";

// import ProfileNavigationComponent from "@/components/navigations/ProfileNavigationComponent.vue";
// import NoDataFoundComponent from "@/components/NoDataFoundComponent.vue";

const ProfileNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "profile-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/ProfileNavigationComponent.vue"));
const NoDataFoundComponent = defineAsyncComponent(() => import(/* webpackChunkName: "no-data" */ /* webpackMode: "lazy" */ "@/components/NoDataFoundComponent.vue"));

export default defineComponent({
  name: "EligibleOrderListPage",
  components: {
    ProfileNavigationComponent,
    NoDataFoundComponent,
  },
  props: { f7router: Object },
  setup(props) {
    const orderList = ref([]);

    const store = useStore();

    const getOrderList = async () => {
      let returnData = await get("/user/eligibleorder/list");
      orderList.value = returnData;
    };

    onMounted(() => {
      getOrderList();
    });

    return {
      orderList,
    };
  },
});
</script>
